// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["SGnjzoEKT"];

const variantClassNames = {SGnjzoEKT: "framer-v-o1vf60"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, N2wxVZHGw: title ?? props.N2wxVZHGw ?? "How It Works"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, N2wxVZHGw, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "SGnjzoEKT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-S0YeZ", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-o1vf60", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"SGnjzoEKT"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter-Medium\", \"Inter\", sans-serif", "--framer-font-weight": "500", "--framer-text-alignment": "center"}}>How It Works</motion.p></React.Fragment>} className={"framer-2ni3kc"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"JwAE39KYG"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={N2wxVZHGw} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-S0YeZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-S0YeZ .framer-1v2ok0x { display: block; }", ".framer-S0YeZ .framer-o1vf60 { height: 24px; overflow: visible; position: relative; width: 111px; }", ".framer-S0YeZ .framer-2ni3kc { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 111
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"N2wxVZHGw":"title"}
 * @framerImmutableVariables false
 */
const FramerwcWXlTAd7: React.ComponentType<Props> = withCSS(Component, css, "framer-S0YeZ") as typeof Component;
export default FramerwcWXlTAd7;

FramerwcWXlTAd7.displayName = "How It Works";

FramerwcWXlTAd7.defaultProps = {height: 24, width: 111};

addPropertyControls(FramerwcWXlTAd7, {N2wxVZHGw: {defaultValue: "How It Works", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerwcWXlTAd7, [])